<template>
  <div>
    <div class="d-flex">
      <h2>プロフィール設定</h2>
      <div class="ml-1">
        <b-badge variant="light-primary">
          講師
        </b-badge>
      </div>
    </div>
    <div class="mb-2">
      プロフィールを完成させて、あなたのスキルを世界の誰かに教えましょう、伝えましょう。<br>このプロフィールの情報はサイトで確認ができる（公開される）情報となります。
    </div>
    <b-card
      title=""
    >
      <b-form>
        <b-row
          v-for="(item, index) in menulist"
          :key="index"
        >
          <InputParts
            v-if="useValueDicList.indexOf(item.type) !== -1"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-dic="inputForm[item.key]"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else-if="useValueListList.indexOf(item.type) !== -1"
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value-list="typeof inputForm[item.key] !== 'undefined'? inputForm[item.key] : []"
            :default-value="typeof item.defaultValue !== 'undefined'? item.defaultValue: []"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type === 'checkboxplain' ? item.select: []"
            @inputData="inputForm[item.key] = $event"
          />
          <InputParts
            v-else
            :label="item.label"
            :type="item.type"
            :choice="typeof item.choice !== 'undefined'? item.choice: []"
            :value="item.type !=='checkbox' ?inputForm[item.key]: ''"
            :disabled="typeof item.disabled !== 'undefined'? item.disabled: false"
            :explain="typeof item.explain !== 'undefined'? item.explain: []"
            :select="item.type ==='checkbox' ? choice: []"
            @inputData="inputForm[item.key] = $event"
          />
        </b-row>
        <div v-if="errorList.length > 0">
          <b-alert
            variant="danger"
            show
            class="alertSpace"
          >
            <div class="alert-body">
              <div class="">
                {{ errorMSG }}
              </div>
              <div
                v-for="(item, index) in errorList"
                :key="index"
                class=""
              >
                ・<b>{{ item }}</b>
              </div>
            </div>
          </b-alert>
        </div>
        <b-row class="mt-2">
          <!-- submit and reset -->
          <b-col offset-md="4">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="confirm"
            >
              登録する
              <b-spinner
                v-if="status === 2"
                small
              />
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol, BForm, BButton, BCard, BSpinner, BAlert, BBadge,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import InputParts from '@/components/molecules/input/InputParts.vue'
import menulist from '@/components/conf/teacher/JPCAREER様_学習PF_講師_プロフィール_20210921.json'
import choice from '@/components/conf/JPCAREER様_学習PF_選択肢.json'
import UpdateUserData from '@/firestore/data/UpdateUserData'
import GetUserData from '@/firestore/data/GetUserData'
import StoreUpdateWithoutFirestore from '@/firestore/store/StoreUpdateWithoutFirestore'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BButton,
    BCard,
    InputParts,
    BSpinner,
    BAlert,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      inputForm: {},
      status: 1,
      isShow: false,
      menulist,
      choice,
      selected: [],
      useValueDicList: ['checkbox', 'selectSingle', 'cancelPolicy', 'radio'],
      useValueListList: ['checkboxplain', 'qualificationInput', 'serviceCategory', 'languageLevelSelect'],
      errorMSG: '',
      errorList: [],
    }
  },
  computed: {
    ...mapGetters('userBasic', ['userType', 'teacherID', 'uid']),
  },
  mounted() {
    this.fetch()
  },
  methods: {
    isInvalid() {
      this.errorList = []
      const datakeys = Object.keys(this.inputForm)
      this.menulist.forEach(item => {
        if (item.required && datakeys.indexOf(item.key) === -1) {
          // window.console.log('invalid', item.label)
          this.errorList.push(item.label)
        }
        if (item.required && typeof this.inputForm[item.key] === 'object') {
          if (Object.keys(this.inputForm[item.key]).length === 0) this.errorList.push(item.label)
        }
      })
      if (this.errorList.length > 0) {
        this.errorMSG = '下記の項目を入力してください。'
        return true
      }
      return false
    },
    async confirm() {
      this.status = 2
      const isInvalid = this.isInvalid()
      if (isInvalid) {
        this.status = 1
        return
      }
      const data = {
        collection: 'profile',
        userType: this.userType,
        data: this.inputForm,
      }
      const updateUserData = new UpdateUserData()
      const response = await updateUserData.update(data)
      if (response.status === 'success') {
        this.success()
        this.fetch()
      } else {
        window.console.log('error', response.msg)
      }
    },
    async fetch() {
      const data = {
        collection: 'profile',
      }
      const getUserData = new GetUserData()
      const response = await getUserData.fetch(data)
      if (response.status === 'success') {
        this.inputForm = response.data
        const storeUpdateWithoutFirestore = new StoreUpdateWithoutFirestore(response.data)
        storeUpdateWithoutFirestore.update()
      } else {
        window.console.log('error', response.msg)
      }
    },
    showBtn() {
      this.$ref.sbchild.$emit('success')
    },
    success() {
      this.$swal({
        title: '登録完了！',
        text: 'データが正常に登録されました。',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {
        this.status = 1
      })
    },
    cancel() {
      this.$router.push({ name: 'teacher-profile' })
    },
  },
}
</script>

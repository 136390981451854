import firebase from 'firebase/app'
import store from '@/store'

export default class UpdateUserData {
  constructor() {
    this.docID = ''
  }

  async update(payload) {
    const { collection, userType, data } = payload
    try {
      if (data.docID) {
        this.docID = data.docID
        // await firebase.firestore().collection(collection).doc(data.docID).set(data, { merge: true })
      } else {
        const promise = await firebase.firestore().collection(collection).doc()
        this.docID = promise.id
        data.docID = promise.id
        data.uid = store.getters['userBasic/uid']
        data.mail = store.getters['userBasic/mail']
        data.userType = userType
        if (userType === 'student') data.studentID = store.getters['userBasic/studentID']
        if (userType === 'teacher') data.teacherID = store.getters['userBasic/teacherID']
        // await firebase.firestore().collection(collection).doc(docID).set(data, { merge: true })
      }
      await firebase.firestore().collection(collection).doc(this.docID).set(data, { merge: true })
      const response = {
        status: 'success',
      }
      return response
    } catch (error) {
      window.console.log('error', error)
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}

<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <div class="">
            <small>{{ description }}</small>
          </div>
          <b-form-input
            v-model="categoryWord"
            :placeholder="placeholderText"
            :readonly="disabled"
            @click="showModal"
            @input="send"
          />
        </b-col>
      </b-row>
      <b-modal
        id="modal-1"
        v-model="isShowModal"
        centered
        size="md"
        title="カテゴリ"
        ok-only
        ok-title="OK"
      >
        <app-collapse>
          <app-collapse-item
            v-for="(item, index) in categoryA"
            :key="index"
            :title="item.category"
          >
            <app-collapse-item
              v-for="(it, index_) in categoryB.filter(v => v.categoryId === item.categoryId)"
              :key="index_"
              :title="it.groupLabel"
            >
              <b-button
                v-for="(d, i) in categoryC.filter(v => v.groupId === it.groupId)"
                :key="i"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="flat-secondary"
                @click="setCategoryWord(d)"
              >
                {{ d.subjectLabel }}
              </b-button>
            </app-collapse-item>
          </app-collapse-item>
        </app-collapse>
      </b-modal>
    </b-form-group>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCol, BFormGroup, BFormInput, BRow, VBPopover, BPopover, BButton, BModal,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import categoryA from '@/components/conf/学習PF_大カテゴリリスト.json'
import categoryB from '@/components/conf/学習PF_大category.json'
import categoryC from '@/components/conf/学習PF_小カテゴリ.json'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BPopover,
    BButton,
    BModal,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: '',
      isShowModal: false,
      categoryA,
      categoryB,
      categoryC,
      categoryWord: '',
      subjectId: 0,
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value !== {}) {
          const ListC = this.categoryC.find(v => v.subjectId === this.value.subjectId)
          this.categoryWord = ListC.subjectLabel
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    send(val) {
      window.console.log('send', val)
      this.$emit('childData', val)
    },
    showModal() {
      this.isShowModal = true
    },
    makeList(val, item) {
      const ListA = val.filter(v => v.categoryId === item.categoryId)
      window.console.log('', ListA)
      return ListA
    },
    setCategoryWord(val) {
      this.categoryWord = val.subjectLabel
      this.subjectId = val.subjectId
      this.send(val)
      this.isShowModal = false
    },
  },
}
</script>

<style scoped>
.explainText {
  padding: 2% 0;
}
.collapse_ {
  margin: -1% -1%;
}
</style>

<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <b-row
            v-for="(item, index) in items"
            :key="index"
            class="mb-3"
          >
            <b-col>
              <div class="">
                <small>資格名{{ index + 1 }}</small>
                <!-- <small>{{ description }}</small> -->
              </div>
              <b-form-input
                v-model="item.qualificationName"
                :placeholder="placeholderText"
                :readonly="disabled"
              />
              <div class="errorMSG">
                <small>{{ errorMSG }}</small>
              </div>
              <div class="mt-1">
                <small>証明書</small>
                <!-- <small>{{ description }}</small> -->
              </div>
              <b-form-file
                v-model="item.file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @input="upload(index)"
              />
              <div
                v-if="item.fileName"
                class="fileName text-primary"
              >
                <feather-icon
                  icon="FileTextIcon"
                  size="18"
                  class=""
                />
                {{ item.fileName }}
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="mb-2"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-25"
            />
            <span>追加</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-2 ml-1"
            @click="removeItem"
          >
            <feather-icon
              icon="XCircleIcon"
              class="mr-25"
            />
            <span>削除</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCol, BFormGroup, BFormInput, BRow, VBPopover, BPopover, BFormFile, BButton,
} from 'bootstrap-vue'
import InputFile from '@/firestore/file/InputFile'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BPopover,
    BFormFile,
    BButton,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    valueList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: '',
      file: null,
      uploadFile: null,
      items: [{}],
      errorMSG: '',
    }
  },
  watch: {
    valueList: {
      handler() {
        if (this.valueList.length !== 0) {
          this.items = this.valueList
        }
      },
      immediate: true,
    },
  },
  methods: {
    send() {
      const newArray = new Array(this.items)
      newArray.forEach(item => {
        const dic = item
        delete dic.file
      })
      window.console.log('memo', newArray)
      // this.$emit('childData', newArray)
    },
    async upload(index) {
      const dic = this.items[index]
      try {
        // if (!dic.qualificationName) {
        //   this.errorMSG = '先に資格名を入力してください。'
        //   window.console.log('memo', dic.file)
        //   this.items[index] = {}
        //   return
        // }
        const inputFile = new InputFile(dic.file)
        const res = await inputFile.upload()
        this.items[index].uploadFile = res.data
        this.items[index].fileName = dic.file.name
        delete dic.file
        this.$emit('childData', this.items)
      } catch (error) {
        window.console.log('error', error)
      }
    },
    repeateAgain() {
      this.items.push({})
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>

<style scoped>
.explainText {
  padding: 2% 0;
}
.collapse_ {
  margin: -1% -1%;
}
.errorMSG {
  color: red;
}
.fileName {
  width: 80%;
  font-size: 0.9rem;
  margin: 2% 0;
}
</style>

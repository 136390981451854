<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
            @click="isShowModal = true"
          />
        </b-col>
        <b-col>
          <div class="">
            <small>{{ description }}</small>
          </div>
          <v-select
            v-model="selected"
            :options="choice"
            :get-option-label="val => val.labelName"
            @input="send"
          />
          <b-form-input
            v-if="selected.value === 1"
            v-model="selected.urldata"
            placeholder="教材のURLを入力して下さい。"
            class="mt-1"
            @input="send"
          />
        </b-col>
      </b-row>
    </b-form-group>
    <b-modal
      v-model="isShowModal"
      centered
      title="info"
      ok-only
      ok-variant="outline-primary"
    >
      <b-row>
        <ul>
          <li
            v-for="(item, index) in explain"
            :key="index"
            class="mb-1"
          >
            {{ item }}
          </li>
        </ul>
      </b-row>
    </b-modal>
  </b-col>
</template>

<script>
import {
  BCol, BFormGroup, BRow, BFormInput, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCol,
    BFormGroup,
    vSelect,
    BRow,
    BFormInput,
    BModal,
  },
  directives: {
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    choice: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: {},
      isShowModal: false,
    }
  },
  watch: {
    value: {
      handler() {
        // window.console.log('🚀 ', this.value, Object.keys(this.value).length)
        if (Object.keys(this.value).length !== 0) {
          this.selected = this.value
          return
        }
        /* eslint-disable prefer-destructuring */
        if (this.defaultValue.length !== 0) this.selected = this.defaultValue[0]
        this.send()
        /* eslint-enable */
        // window.console.log('🌺', this.selected, this.defaultValue)
      },
      immediate: true,
      deep: true,
    },
    selected: {
      handler() {
        this.send()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    send() {
      this.$emit('childData', this.selected)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.explainText {
  padding: 3% 0;
}
.collapse_ {
  margin: -1% -1%;
}
</style>

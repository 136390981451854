<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            v-if="explain.length > 0"
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <div class="">
            <small>{{ description }}</small>
          </div>
          <b-form-tags
            v-model="selected"
            separator=" ,"
            placeholder=""
            size="lg"
            add-button-text="Add"
            class="tagSpace"
          />
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>

<script>
import {
  BCol, BFormTags, BFormGroup, BRow, VBPopover, BPopover,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BFormTags,
    BFormGroup,
    BRow,
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
    }
  },
  watch: {
    value: {
      handler() {
        // window.console.log('🚀 ', this.value, Object.keys(this.value).length)
        if (this.value.length !== 0) {
          this.selected = this.value
          return
        }
        /* eslint-disable prefer-destructuring */
        if (this.defaultValue.length !== 0) {
          this.selected = this.defaultValue[0]
        }
        this.send()
        /* eslint-enable */
        // window.console.log('🌺', this.selected, this.defaultValue)
      },
      immediate: true,
    },
    selected: {
      handler() {
        this.send()
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    send() {
      this.$emit('childData', this.selected)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

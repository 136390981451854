<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            v-if="explain.length > 0"
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <div class="">
            <small>{{ description }}</small>
          </div>
          <div class="">
            <quill-editor
              id="quil-content"
              v-model="data"
              :options="editorOption"
              @input="send"
            />
            <!-- {{ data }} -->
          </div>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCol, BFormGroup, BRow, VBPopover, BPopover,
} from 'bootstrap-vue'
import { quillEditor } from 'vue-quill-editor'

export default {
  components: {
    BCol,
    BFormGroup,
    BRow,
    BPopover,
    quillEditor,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: '',
      editorOption: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            // ['blockquote', 'code-block'],
            // [{ header: 1 }, { header: 2 }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            // [{ script: 'sub' }, { script: 'super' }],
            // [{ indent: '-1' }, { indent: '+1' }],
            // [{ direction: 'rtl' }],
            // [{ size: ['small', false, 'large', 'huge'] }],
            // [{ header: [1, 2, 3, 4, 5, 6, false] }],
            // // [{ font: [] }],
            // [{ color: [] }, { background: [] }],
            // [{ align: [] }],
            // ['clean'],
            // ['link', 'image', 'video'],
          ],
          // toolbar: '#quill-toolbar',
        },
        placeholder: '',
      },
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value !== '') {
          this.data = this.value
        }
      },
      immediate: true,
    },
  },
  methods: {
    send() {
      this.$emit('childData', this.data)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style scoped>
.explainText {
  padding: 2% 0;
}
.collapse_ {
  margin: -1% -1%;
}
.editorSpace {
  height: 200px;
}
.quill-editor {
  height: 150px;
  margin: 0 0 55% 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .quill-editor {
    height: 200px;
    margin: 0 0 25% 0;
  }
}
</style>

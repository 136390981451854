<template>
  <b-col cols="12">
    <b-row>
      <BasicInput
        v-if="type === ''"
        :label="label"
        placeholder-text=""
        :value="value"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        :disabled="disabled"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BasicInputNumeric
        v-if="type === 'basicInputNumeric'"
        :label="label"
        placeholder-text=""
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        :disabled="disabled"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BirthdayInput
        v-if="type === 'birthdayInput'"
        :label="label"
        placeholder-text=""
        :value="value"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        :disabled="disabled"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BirthdaySelect
        v-if="type === 'birthdaySelect'"
        :label="label"
        placeholder-text=""
        :value="value"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        :disabled="disabled"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <YearMonthSelect
        v-if="type === 'yearMonthSelect'"
        :label="label"
        :value="typeof valueDate !== 'undefined'? valueDate : null"
        :description="typeof description !== 'undefined'? description : ''"
        :disabled="disabled"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <InputHTML
        v-if="type === 'htmlText'"
        :label="label"
        placeholder-text=""
        :value="value"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        :disabled="disabled"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <QualificationInput
        v-if="type === 'qualificationInput'"
        :label="label"
        placeholder-text=""
        :value-list="valueList"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        :disabled="disabled"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <LearningCategoryInput
        v-if="type === 'learningCategoryInput'"
        :label="label"
        placeholder-text=""
        :value="typeof valueNum !== 'undefined' ? valueNum : 0"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        :disabled="disabled"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <ServiceCategoryInput
        v-if="type === 'serviceCategory'"
        :label="label"
        placeholder-text=""
        :value="typeof valueList !== 'undefined'? valueList : []"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BasicTextErea
        v-if="type === 'texterea'"
        :label="label"
        placeholder-text=""
        :value="value"
        :disabled="disabled"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <ColTextErea
        v-if="type === 'coltexterea'"
        :label="label"
        placeholder-text=""
        :value="value"
        :description="typeof description !== 'undefined'? description : ''"
        :disabled="disabled"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BasicSwitch
        v-if="type === 'switch'"
        :label="label"
        :value="value"
        :choice="typeof choice !== 'undefined'? choice : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <ScopeSwitch
        v-if="type === 'scopeSwitch'"
        :label="label"
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :choice="typeof choice !== 'undefined'? choice : []"
        :default-value="typeof defaultValue !== 'undefined'? defaultValue : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <MultipleSelect
        v-if="type === 'multipleSelect'"
        :label="label"
        :value="value"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <DateRepeaterLesson
        v-if="type === 'dateRepeater'"
        :label="label"
        :value="typeof value !== 'undefined'? value : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        :timelength="timelength"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BasicFileInput
        v-if="type === 'fileInput'"
        :label="label"
        :value="value"
        :directory="directory"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <CheckBoxBasic
        v-if="type === 'checkbox'"
        :label="label"
        :value="valueDic"
        :select="select"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <CheckBoxPlain
        v-if="type === 'checkboxplain'"
        :label="label"
        :value="typeof valueList !== 'undefined'? valueList : []"
        :select="select"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <CheckBoxSearch
        v-if="type === 'checkboxSearch'"
        :label="label"
        :select="select"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BasicRadio
        v-if="type === 'radio'"
        :label="label"
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :default-value="typeof defaultValue !== 'undefined'? defaultValue : []"
        :select="choice"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <SingleSelect
        v-if="type === 'selectSingle'"
        :label="label"
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :default-value="typeof defaultValue !== 'undefined'? defaultValue : []"
        :description="typeof description !== 'undefined'? description : ''"
        :choice="typeof choice !== 'undefined'? choice : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <LessonCategorySelect
        v-if="type === 'lessonCategorySelect'"
        :label="label"
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :default-value="typeof defaultValue !== 'undefined'? defaultValue : []"
        :description="typeof description !== 'undefined'? description : ''"
        :choice="typeof choice !== 'undefined'? choice : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <ResidenceStatusSelect
        v-if="type === 'residenceStatus'"
        :label="label"
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :default-value="typeof defaultValue !== 'undefined'? defaultValue : []"
        :description="typeof description !== 'undefined'? description : ''"
        :choice="typeof choice !== 'undefined'? choice : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <CancelPolicy
        v-if="type === 'cancelPolicy'"
        :label="label"
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :default-value="typeof defaultValue !== 'undefined'? defaultValue : []"
        :description="typeof description !== 'undefined'? description : ''"
        :choice="typeof choice !== 'undefined'? choice : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <SingleSelectBoolean
        v-if="type === 'singleSelectBoolean'"
        :label="label"
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :default-value="typeof defaultValue !== 'undefined'? defaultValue : []"
        :description="typeof description !== 'undefined'? description : ''"
        :choice="typeof choice !== 'undefined'? choice : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <TeacherSingleSelect
        v-if="type === 'teacherSelectSingle'"
        :label="label"
        :value="typeof valueDic !== 'undefined'? valueDic : {}"
        :default-value="typeof defaultValue !== 'undefined'? defaultValue : []"
        :description="typeof description !== 'undefined'? description : ''"
        :choice="typeof choice !== 'undefined'? choice : []"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <LanguageLevelSelect
        v-if="type === 'languageLevelSelect'"
        :label="label"
        :value-list="typeof valueList !== 'undefined'? valueList : []"
        :default-value="typeof valueList !== 'undefined'? valueList : []"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BasicTagForm
        v-if="type === 'tagForm'"
        :label="label"
        :value="typeof valueList !== 'undefined'? valueList : []"
        :description="typeof description !== 'undefined'? description : ''"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
    <b-row>
      <BasicRating
        v-if="type === 'rating'"
        :label="label"
        :value="typeof valueNum !== 'undefined'? valueNum : 0"
        :disabled="disabled"
        :explain="typeof explain !== 'undefined'? explain : []"
        @childData="send($event)"
      />
    </b-row>
  </b-col>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import BasicInput from '@/components/ui/form/input/BasicInput.vue'
import BasicSwitch from '@/components/ui/form/switch/BasicSwitch.vue'
import ScopeSwitch from '@/components/ui/form/switch/ScopeSwitch.vue'
import MultipleSelect from '@/components/ui/form/select/MultipleSelect.vue'
// import DateRepeater from '@/components/ui/form/datePicker/DateRepeater.vue'
import BasicFileInput from '@/components/ui/form/fileInput/BasicFileInput.vue'
import CheckBoxBasic from '@/components/molecules/checkbox/CheckBoxBasic.vue'
import SingleSelect from '@/components/ui/form/select/SingleSelect.vue'
import BasicRadio from '@/components/ui/form/radio/BasicRadio.vue'
import BasicRating from '@/components/ui/form/rating/BasicRating.vue'
import BasicTextErea from '@/components/ui/form/input/BasicTextErea.vue'
import ColTextErea from '@/components/ui/form/input/ColTextErea.vue'
import BasicTagForm from '@/components/ui/form/tag/BasicTagForm.vue'
import TeacherSingleSelect from '@/components/ui/individual/teacher/select/SingleSelect.vue'
import CheckBoxSearch from '@/components/molecules/checkbox/CheckBoxSearch.vue'
import CheckBoxPlain from '@/components/molecules/checkbox/CheckBoxPlain.vue'
import LearningCategoryInput from '@/components/ui/form/input/LearningCategoryInput.vue'
import DateRepeaterLesson from '@/components/ui/form/datePicker/DateRepeaterLesson.vue'
import InputHTML from '@/components/ui/form/input/InputHTML.vue'
import LanguageLevelSelect from '@/components/ui/form/select/LanguageLevelSelect.vue'
import BirthdaySelect from '@/components/ui/form/select/BirthdaySelect.vue'
import QualificationInput from '@/components/ui/form/input/QualificationInput.vue'
import SingleSelectBoolean from '@/components/ui/form/select/SingleSelectBoolean.vue'
import ServiceCategoryInput from '@/components/ui/form/input/ServiceCategoryInput.vue'
import LessonCategorySelect from '@/components/ui/individual/teacher/select/LessonCategorySelect.vue'
import CancelPolicy from '@/components/ui/individual/teacher/cancel/CancelPolicy.vue'
import ResidenceStatusSelect from '@/components/ui/individual/jobSeeker/select/ResidenceStatusSelect.vue'
import YearMonthSelect from '@/components/ui/form/select/YearMonthSelect.vue'
import BasicInputNumeric from '@/components/ui/form/input/BasicInputNumeric.vue'
import BirthdayInput from '@/components/ui/form/input/BirthdayInput.vue'

export default {
  components: {
    BasicInput,
    BasicSwitch,
    ScopeSwitch,
    MultipleSelect,
    // DateRepeater,
    BasicFileInput,
    CheckBoxBasic,
    SingleSelect,
    BasicRadio,
    BasicRating,
    BasicTextErea,
    ColTextErea,
    BasicTagForm,
    TeacherSingleSelect,
    CheckBoxSearch,
    CheckBoxPlain,
    LearningCategoryInput,
    DateRepeaterLesson,
    LanguageLevelSelect,
    InputHTML,
    BirthdaySelect,
    QualificationInput,
    SingleSelectBoolean,
    ServiceCategoryInput,
    LessonCategorySelect,
    ResidenceStatusSelect,
    CancelPolicy,
    YearMonthSelect,
    BasicInputNumeric,
    BirthdayInput,
    BRow,
    BCol,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number, Array],
      default: '',
    },
    valueNum: {
      type: Number,
      default: 0,
    },
    valueDic: {
      type: Object,
      default: () => {},
    },
    /* eslint-disable vue/require-prop-types */
    valueDate: {
      default: null,
    },
    /* eslint-enable */
    valueList: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    choice: {
      type: Array,
      default: () => [],
    },
    select: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    description: {
      type: String,
      default: '',
    },
    directory: {
      type: String,
      default: '',
    },
    explain: {
      type: Array,
      default: () => [],
    },
    timelength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    send(data) {
      this.$emit('inputData', data)
    },
  },
}
</script>

<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            v-if="explain.length > 0"
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <b-row
            v-for="(item, index) in items"
            :key="index"
            class="mb-1"
          >
            <b-col lg="6">
              <v-select
                v-model="item.lang"
                :options="langList[0]['choice']"
                :get-option-label="val => val.labelName"
                class=""
                @input="send"
              />
            </b-col>
            <b-col>
              <v-select
                v-model="item.level"
                :options="langLevel[0]['choice']"
                :get-option-label="val => val.labelName"
                class=""
                @input="send"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="mb-2"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-25"
            />
            <span>追加</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-2 ml-1"
            @click="removeItem"
          >
            <feather-icon
              icon="XCircleIcon"
              class="mr-25"
            />
            <span>削除</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>

<script>
import {
  BCol, BFormGroup, BRow, VBPopover, BPopover, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css'
import langList from '@/components/conf/言語リスト.json'
import langLevel from '@/components/conf/言語レベル.json'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCol,
    BFormGroup,
    vSelect,
    BRow,
    BPopover,
    BButton,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    valueList: {
      type: Array,
      default: () => [],
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      langList,
      langLevel,
      items: [{}],
    }
  },
  watch: {
    valueList: {
      handler() {
        if (this.valueList.length > 0) {
          this.items = this.valueList
        }
      },
      immediate: true,
    },
  },
  // watch: {
  //   value: {
  //     handler() {
  //       // window.console.log('🚀 ', this.value, Object.keys(this.value).length)
  //       if (Object.keys(this.value).length !== 0) {
  //         this.selected = this.value
  //         return
  //       }
  //       /* eslint-disable prefer-destructuring */
  //       if (this.defaultValue.length !== 0) this.selected = this.defaultValue[0]
  //       this.send()
  //       /* eslint-enable */
  //       // window.console.log('🌺', this.selected, this.defaultValue)
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  //   selected: {
  //     handler() {
  //       this.send()
  //     },
  //     immediate: true,
  //     deep: true,
  //   },
  // },
  methods: {
    send() {
      this.$emit('childData', this.items)
    },
    repeateAgain() {
      this.items.push({})
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.explainText {
  padding: 3% 0;
}
.collapse_ {
  margin: -1% -1%;
}
.selectInline {
  display: flex;
}
</style>

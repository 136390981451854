<template>
  <b-row class="match-height">
    <b-col
      lg="8"
      class="space_"
    >
      <EditProfile />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EditProfile from '@/components/teacher/profile/EditProfile.vue'

export default {
  components: {
    BRow,
    BCol,
    EditProfile,
  },
}
</script>

<style scoped>
.space_ {
  margin: 0 auto;
}
</style>

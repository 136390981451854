<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            v-if="explain.length > 0"
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <b-row
            v-for="(item, index) in items"
            :key="index"
            class="mb-1"
          >
            <b-col>
              <div class="">
                <small>カテゴリ{{ index + 1 }}</small>
                <!-- <small>{{ description }}</small> -->
              </div>
              <b-form-input
                v-model="item.subjectLabel"
                :placeholder="placeholderText"
                :readonly="disabled"
                @click="showModal(index)"
                @input="send"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="mb-2"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-25"
            />
            <span>追加</span>
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-2 ml-1"
            @click="removeItem"
          >
            <feather-icon
              icon="XCircleIcon"
              class="mr-25"
            />
            <span>削除</span>
          </b-button>
        </b-col>
      </b-row>
    </b-form-group>
    <b-modal
      id="modal-1"
      v-model="isShowModal"
      centered
      size="md"
      title="カテゴリ"
      ok-only
      ok-title="OK"
    >
      <app-collapse>
        <app-collapse-item
          v-for="(item, index) in categoryA"
          :key="index"
          :title="item.category"
        >
          <app-collapse-item
            v-for="(it, index_) in categoryB.filter(v => v.categoryId === item.categoryId)"
            :key="index_"
            :title="it.groupLabel"
          >
            <b-button
              v-for="(d, i) in categoryC.filter(v => v.groupId === it.groupId)"
              :key="i"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="flat-secondary"
              @click="setCategoryWord(d)"
            >
              {{ d.subjectLabel }}
            </b-button>
          </app-collapse-item>
        </app-collapse-item>
      </app-collapse>
    </b-modal>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCol, BFormGroup, BFormInput, BRow, VBPopover, BPopover, BButton, BModal,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import categoryA from '@/components/conf/学習PF_大カテゴリリスト.json'
import categoryB from '@/components/conf/学習PF_大category.json'
import categoryC from '@/components/conf/学習PF_小カテゴリ.json'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BPopover,
    BButton,
    BModal,
    AppCollapse,
    AppCollapseItem,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: '',
      file: null,
      uploadFile: null,
      items: [{}],
      isShowModal: false,
      categoryA,
      categoryB,
      categoryC,
      categoryWord: {},
      subjectId: 0,
      index2: '',
      categoryList: {},
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value.length > 0) {
          // const ListC = this.categoryC.find(v => v.subjectId === this.value.subjectId)
          // this.categoryWord = ListC.subjectLabel
          this.items = this.value
        }
      },
      immediate: true,
    },
  },
  methods: {
    send() {
      this.$emit('childData', this.items)
    },
    repeateAgain() {
      if (this.items.length < 3) this.items.push({})
    },
    removeItem(index) {
      delete this.categoryList[this.items.length - 1]
      this.items.splice(index, 1)
    },
    showModal(val) {
      this.isShowModal = true
      this.index2 = val
    },
    makeList(val, item) {
      const ListA = val.filter(v => v.categoryId === item.categoryId)
      window.console.log('', ListA)
      return ListA
    },
    setCategoryWord(val) {
      // const data = {
      //   categoryWord: val.subjectLabel,
      //   subjectId: val.subjectId,
      // }
      this.categoryList[this.index2] = val.subjectLabel
      this.items[this.index2] = val
      // this.categoryList[this.index2] = data
      // this.categoryWord = val.subjectLabel
      // this.subjectId = val.subjectId
      this.send()
      this.isShowModal = false
    },
  },
}
</script>

<style scoped>
.explainText {
  padding: 2% 0;
}
.collapse_ {
  margin: -1% -1%;
}
</style>

<template>
  <b-col cols="12">
    <b-form-group
      :label="label"
      label-cols-md="6"
    >
      <!-- <div class="demo-inline-spacing mb-1"> -->
      <b-form-rating
        v-model="selected"
        variant="primary"
        no-border
        :readonly="disabled"
        @change="send"
      />
      <!-- </div>color="#191970" -->
    </b-form-group>
  </b-col>
</template>

<script>
import { BFormRating, BFormGroup, BCol } from 'bootstrap-vue'

export default {
  components: {
    BFormRating,
    BFormGroup,
    BCol,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Number,
      default: null,
    },
    select: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: null,
      titleList: [],
      choiceData: {},
    }
  },
  watch: {
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.value !== null) {
          this.selected = this.value
        }
      },
      immediate: true,
    },
  },
  // mounted() {
  //   window.console.log('🐶✨️ rating', this.selected)
  // },
  methods: {
    send() {
      // this.selected[item].push(num)
      // window.console.log('🚀🚀', this.selected, this.label)
      this.$emit('childData', this.selected)
    },
  },
}
</script>

import firebase from 'firebase/app'
import store from '@/store'

export default class GetUserData {
  constructor() {
    this.dic = {}
    this.userIdTypeName = ''
    this.userID = ''
  }

  getUserIdType(userType) {
    switch (userType) {
      case 'student':
        this.userIdTypeName = 'studentID'
        this.userID = store.getters['userBasic/studentID']
        break
      case 'teacher':
        this.userIdTypeName = 'teacherID'
        this.userID = store.getters['userBasic/teacherID']
        break
      case 'company':
        this.userIdTypeName = 'companyID'
        this.userID = store.getters['userBasic/companyID']
        break
      default:
        this.userIdTypeName = 'studentID'
        this.userID = store.getters['userBasic/studentID']
        break
    }
  }

  async fetch(payload) {
    const { collection } = payload
    try {
      const userType = store.getters['userBasic/userType']
      this.getUserIdType(userType)
      await firebase.firestore().collection(collection)
        .where(this.userIdTypeName, '==', this.userID)
        .get()
        .then(sn => {
          if (!sn.empty) {
            sn.forEach(doc => {
              this.dic = doc.data()
            })
          }
        })

      const response = {
        status: 'success',
        data: this.dic,
      }
      return response
    } catch (error) {
      window.console.log('error', error)
      const response = {
        status: 'error',
        msg: error,
      }
      return response
    }
  }
}

import formatDate from '@/components/js/filter/dateFormat'

export default function dateformate(val) {
  if (val) {
    try {
      // const date = val.toDate()
      return formatDate(val, 'yyyy-MM-dd HH:mm')
    } catch (error) {
      window.console.log(error)
      return ''
    }
  }
  return ''
}

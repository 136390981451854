<template>
  <div>
    <div class="mt-2">
      <b>オリジナルキャンセルポリシー</b>
    </div>
    <div class="mb-2">
      <small>キャンセルポリシーを選択することでカスタマイズできます。</small>
    </div>
    <div class="mb-1">
      <div class="text-primary">
        <b>eラーニング</b>
      </div>
      <div class="">
        ・申込確定後のご返金は承っておりません。
      </div>
    </div>
    <hr>
    <div class="mb-1">
      <div class="text-primary">
        <b>オンラインレッスン</b>
      </div>
      <div class="mt-1 mb-2">
        <div class="">
          <b>リクエスト確定前</b>
        </div>
        <div class="mt-1">
          ・キャンセルなし
        </div>
      </div>
      <div class="mb-2">
        <div class="">
          <b>リクエスト・即時申込確定後</b>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・レッスン開始時刻の72時間以上前
            </b-col>
            <b-col>
              <v-select
                v-model="selected.service1"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・レッスン開始時刻の72時間未満～24時間以上前
            </b-col>
            <b-col>
              <v-select
                v-model="selected.service2"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・レッスン開始時刻の24時間未満～12時間以上前
            </b-col>
            <b-col>
              <v-select
                v-model="selected.service3"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・レッスン開始時刻の12時間未満～開始10分後
            </b-col>
            <b-col>
              <v-select
                v-model="selected.service4"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・レッスンに現れなかった場合
            </b-col>
            <b-col>
              <v-select
                v-model="selected.service5"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <hr>
      </div>
    </div>
    <div class="mb-1">
      <div class="text-primary">
        <b>オンラインイベント</b>
      </div>
      <div class="mt-1 mb-2">
        <div class="">
          <b>リクエスト確定前</b>
        </div>
        <div class="mt-1">
          ・キャンセルなし
        </div>
      </div>
      <div class="mb-2">
        <div class="">
          <b>リクエスト・即時申込確定後</b>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オンラインイベント開始時刻の48時間以上前
            </b-col>
            <b-col>
              <v-select
                v-model="selected.online1"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オンラインイベント開始時刻の48時間未満～24時間以上前
            </b-col>
            <b-col>
              <v-select
                v-model="selected.online2"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オンラインイベント開始時刻の24時間未満～開始10分後
            </b-col>
            <b-col>
              <v-select
                v-model="selected.online3"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オンラインイベントに現れなかった場合
            </b-col>
            <b-col>
              <v-select
                v-model="selected.online5"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <hr>
      </div>
    </div>
    <div class="mb-1">
      <div class="text-primary">
        <b>オフラインイベント</b>
      </div>
      <div class="mt-1 mb-2">
        <div class="">
          <b>リクエスト確定前</b>
        </div>
        <div class="mt-1">
          ・キャンセルなし
        </div>
      </div>
      <div class="mb-2">
        <div class="">
          <b>リクエスト・即時申込確定後</b>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オフラインイベント開始時刻の72時間以上前
            </b-col>
            <b-col>
              <v-select
                v-model="selected.offline1"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オフラインイベント開始時刻の72時間未満～48時間以上前
            </b-col>
            <b-col>
              <v-select
                v-model="selected.offline2"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オフラインイベント開始時刻の48時間未満～24時間以上前
            </b-col>
            <b-col>
              <v-select
                v-model="selected.offline3"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オフラインイベント開始時刻の24時間未満～開始10分後
            </b-col>
            <b-col>
              <v-select
                v-model="selected.offline4"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-row>
            <b-col
              lg="8"
              class=""
            >
              ・オフラインイベントに現れなかった場合
            </b-col>
            <b-col>
              <v-select
                v-model="selected.offline5"
                :options="choice"
                :get-option-label="val => val.labelName"
                @input="send"
              />
            </b-col>
          </b-row>
        </div>
        <hr>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      inputForm: {},
      selected: {
        service1: {
          labelName: '0%',
          value: 0,
        },
        service2: {
          labelName: '20%',
          value: 20,
        },
        service3: {
          labelName: '50%',
          value: 50,
        },
        service4: {
          labelName: '100%',
          value: 100,
        },
        service5: {
          labelName: '100%',
          value: 100,
        },
        online1: {
          labelName: '0%',
          value: 0,
        },
        online2: {
          labelName: '50%',
          value: 50,
        },
        online3: {
          labelName: '100%',
          value: 100,
        },
        online5: {
          labelName: '100%',
          value: 100,
        },
        offline1: {
          labelName: '0%',
          value: 0,
        },
        offline2: {
          labelName: '20%',
          value: 20,
        },
        offline3: {
          labelName: '50%',
          value: 50,
        },
        offline4: {
          labelName: '100%',
          value: 100,
        },
        offline5: {
          labelName: '100%',
          value: 100,
        },
      },
      choice: [
        {
          labelName: '10%',
          value: 10,
        },
        {
          labelName: '20%',
          value: 20,
        },
        {
          labelName: '30%',
          value: 30,
        },
        {
          labelName: '40%',
          value: 40,
        },
        {
          labelName: '50%',
          value: 50,
        },
        {
          labelName: '60%',
          value: 60,
        },
        {
          labelName: '70%',
          value: 70,
        },
        {
          labelName: '80%',
          value: 80,
        },
        {
          labelName: '90%',
          value: 90,
        },
        {
          labelName: '100%',
          value: 100,
        },
      ],
    }
  },
  mounted() {
    if (Object.keys(this.value).length > 0) {
      this.selected = this.value
    }
    this.send()
  },
  methods: {
    send() {
      this.$emit('childData', this.selected)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.copy_ {
  font-size: 1.1rem;
}
</style>

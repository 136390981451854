<template>
  <b-col cols="12">
    <b-row class="mb-1">
      <b-col lg="4">
        {{ label }}
        <feather-icon
          v-if="explain.length > 0"
          :id="label"
          icon="HelpCircleIcon"
          size="17"
          class="ml-1"
        />
        <b-popover
          v-if="explain.length > 0"
          :target="label"
          triggers="hover"
          placement="top"
        >
          <template #title>
            <span>info</span>
          </template>
          <span>{{ explain[0] }}</span>
        </b-popover>
      </b-col>
      <b-col>
        <b-form-file
          v-model="file"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          @input="upload"
        />
        <b-img
          v-if="uploadFile !== null"
          height="100px"
          class="mt-1 imgSpace"
          :src="uploadFile"
        />
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import {
  BFormFile, BImg, BRow, BCol, VBPopover, BPopover,
} from 'bootstrap-vue'
// import InputFile from '@/firestore/file/InputFile'
import InputFileDirectory from '@/firestore/file/InputFileDirectory'

export default {
  components: {
    BFormFile,
    BImg,
    BRow,
    BCol,
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    directory: {
      type: String,
      default: '',
      required: true,
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      file: null,
      uploadFile: null,
    }
  },
  watch: {
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.value !== '') {
          this.uploadFile = this.value
        }
      },
      immediate: true,
    },
  },
  methods: {
    async upload() {
      window.console.log('memo', this.directory)
      if (!this.file) return
      const inputFile = new InputFileDirectory(this.file)
      const res = await inputFile.upload({ directory: this.directory })
      this.uploadFile = res.data
      this.$emit('childData', res.data)
      // /* eslint-disable */
      // try {
      //   const storage = firebase.storage()
      //   const storageRef = await storage.ref('articles/' + this.file.name)
      //   await storageRef.put(this.file)
      //   this.uploadFile = await storageRef.getDownloadURL()
      //   this.$emit('childData', this.uploadFile)
      // } catch (error) {
      //   window.console.log('error', error)
      // }
      // /* eslint-enable */
    },
  },
}
</script>

<style scoped>
.imgSpace {
  outline: 1px solid #e4e4e4;
  padding: 5px;
}
</style>

import store from '@/store'

export default class StoreUpdateWithoutFirestore {
  constructor(data) {
    this.data = data
  }

  update() {
    if (this.data.nickname) store.dispatch('userBasic/updateUserNickName', this.data.nickname)
    if (this.data.avatar) store.dispatch('userBasic/updateAvatar', this.data.avatar)
  }
}

<template>
  <b-col cols="12">
    <!-- <div class="mb-2">
      {{ label }}
    </div> -->
    <b-tabs align="right">
      <b-tab>
        <template #title>
          <feather-icon icon="GridIcon" />
          <span>一括登録</span>
        </template>
        <div>
          <b-row
            v-for="(item, index) in items"
            :id="item.id"
            :key="index"
            ref="row"
            class=""
          >
            <b-col
              lg="12"
              md="12"
            >
              <div class="flex_ mb-1">
                <div class="">
                  - {{ index + 1 }} -
                </div>
                <div class="">
                  <a>
                    <feather-icon
                      size="16"
                      icon="XCircleIcon"
                      class="mr-50 text-danger"
                      @click="removeItem(index)"
                    />
                  </a>
                </div>
              </div>
            </b-col>

            <b-col
              lg="6"
            >
              <b-form-group
                label="開始日時"
                label-for="start-date"
              >
                <flat-pickr
                  id="start-date"
                  v-model="item.start"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i', locale: Japanese,}"
                />
              </b-form-group>
            </b-col>
            <b-col
              lg="6"
            >
              <b-form-group
                label="終了日時"
                label-for="end-date"
              >
                <b-form-input
                  id="end-date"
                  v-model="item.end"
                  :value="setEndTime(item.start)"
                  readonly
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
        </div>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="flat-primary"
          size="sm"
          class="mb-2"
          @click="repeateAgain"
        >
          <feather-icon
            icon="PlusCircleIcon"
            class="mr-25"
          />
          <span>追加</span>
        </b-button>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="RotateCwIcon" />
          <span>繰り返し登録</span>
        </template>
        開発中...
      </b-tab>
    </b-tabs>
  </b-col>
</template>

<script>
import {
  BFormGroup, BRow, BCol, BButton, BFormInput, BTabs, BTab,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { Japanese } from 'flatpickr/dist/l10n/ja'
// import vSelect from 'vue-select'
import hourChoice from '@/components/conf/country/time_JP.json'
import dateformate from '@/components/js/filter/formatDateMinutesUniversal'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BTabs,
    BTab,
    flatPickr,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    timelength: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      items: [{}],
      nextTodoId: 1,
      Japanese,
      hourChoice,
      selected: {},
      choice: [1, 2, 3, 4, 5],
      hours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      munites: [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55],
    }
  },
  watch: {
    items: {
      handler() {
        /* eslint-disable */
        for (const i in this.items) {
          const item = this.items[i]
          // window.console.log('', item.start, item.end)
          // window.console.log('🍌',  (new Date(item.end) - new Date(item.start))/60000)
          // item.msg = ''
          // if (item.end < item.start) {
          //   item.msg = '開始日時以降の日時を選択してください。'
          //   return
          // }
          item.msg = ''
          item.end = this.setEndTime(item.start)
        }
        this.send(this.items)
        /* eslint-enable  */
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    repeateAgain() {
      this.items.push({
        start: this.setEndTime(this.items[this.items.length - 1].start),
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    send(data) {
      this.$emit('childData', data)
    },
    setEndTime(val) {
      if (val) {
        const dt = new Date(val)
        const endtime = dt.setMinutes(dt.getMinutes() + Number(this.timelength))
        return dateformate(new Date(endtime))
      }
      return ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.msgText {
  color: red;
  font-size: 0.8rem;
}
.flex_ {
  display: flex;
  justify-content: space-between;
}
</style>

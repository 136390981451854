<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="8">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            v-if="explain.length > 0"
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="selected"
            switch
            inline
            :name="label"
            class="switchSpace"
            @input="send"
          >
            {{ msg }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>

<script>
import {
  BFormCheckbox, BFormGroup, BRow, BCol, VBPopover, BPopover,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckbox,
    BRow,
    BCol,
    BPopover,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    value: {
      type: Object,
      default: () => {},
    },
    choice: {
      type: Array,
      default: () => [],
    },
    defaultValue: {
      type: Array,
      default: () => [],
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: null,
      msg: '',
    }
  },
  watch: {
    value: {
      handler() {
        if (Object.keys(this.value).length !== 0) {
          this.selected = this.value.value === 1
          return
        }
        /* eslint-disable prefer-destructuring */
        if (this.defaultValue.length !== 0) {
          const val = this.defaultValue[0]
          this.selected = val.value === 1
        }
        this.send()
        /* eslint-enable */
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    send() {
      const value = this.selected === true ? 1 : 2
      const input = {
        value,
        labelName: value === 1 ? 'ON' : 'OFF',
      }
      this.$emit('childData', input)
      if (this.selected) {
        this.msg = 'ON'
      } else {
        this.msg = 'OFF'
      }
    },
  },
}
</script>
<style scoped>
.switchSpace {
  margin: 2% 0 0 0;
}
</style>

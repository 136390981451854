import firebase from 'firebase/app'

export default class InputFile {
  constructor(file) {
    this.file = file
    this.uploadFile = ''
  }

  async upload(payload) {
    try {
      const { directory } = payload
      const storage = firebase.storage()
      const place = `${directory}/${this.file.name}`

      const storageRef = await storage.ref(place)
      await storageRef.put(this.file)
      this.uploadFile = await storageRef.getDownloadURL()

      const response = {
        data: this.uploadFile,
      }
      return response
    } catch (error) {
      window.console.log('error', error)
      const response = {
        msg: error,
      }
      return response
    }
  }
}

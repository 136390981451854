<template>
  <b-col cols="12">
    <b-row>
      <b-col lg="4">
        <div class="ml-1">
          {{ label }}
        </div>
      </b-col>
      <b-col>
        <div class="demo-inline-spacing_origin mb-2">
          <b-form-checkbox
            v-for="(it, index) in select"
            :key="index"
            v-model="selected"
            :value="it"
            @input="send"
          >
            {{ it }}
          </b-form-checkbox>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { BFormCheckbox, BRow, BCol } from 'bootstrap-vue'

export default {
  components: {
    BFormCheckbox,
    BRow,
    BCol,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    value: {
      type: Array,
      default: () => [],
    },
    select: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected: [],
      inputForm: {},
    }
  },
  watch: {
    value: {
      handler() {
        // 編集する際に既にデータが登録されている場合それを反映する
        if (this.value !== []) {
          this.selected = this.value
        }
      },
      immediate: true,
    },
  },
  mounted() {
  },
  methods: {
    send() {
      this.$emit('childData', this.selected)
    },
  },
}
</script>

<template>
  <b-col cols="12">
    <b-form-group>
      <b-row>
        <b-col lg="4">
          {{ label }}
          <feather-icon
            v-if="explain.length > 0"
            :id="label"
            icon="HelpCircleIcon"
            size="17"
            class="ml-1"
          />
          <b-popover
            v-if="explain.length > 0"
            :target="label"
            triggers="hover"
            placement="top"
          >
            <template #title>
              <span>info</span>
            </template>
            <span>{{ explain[0] }}</span>
          </b-popover>
        </b-col>
        <b-col>
          <div class="">
            <small>{{ description }}</small>
          </div>
          <b-form-input
            v-model="data"
            :placeholder="placeholderText"
            :readonly="disabled"
            @input="send"
          />
        </b-col>
      </b-row>
    </b-form-group>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  BCol, BFormGroup, BFormInput, BRow, VBPopover, BPopover,
} from 'bootstrap-vue'

export default {
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BRow,
    BPopover,
  },
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    placeholderText: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    description: {
      type: String,
      default: '',
    },
    explain: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      data: '',
    }
  },
  watch: {
    value: {
      handler() {
        if (this.value !== '') {
          this.data = this.value
        }
      },
      immediate: true,
    },
  },
  methods: {
    send() {
      this.$emit('childData', this.data)
    },
  },
}
</script>

<style scoped>
.explainText {
  padding: 2% 0;
}
.collapse_ {
  margin: -1% -1%;
}
</style>
